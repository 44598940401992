<template>
  <div class="data_container">
    <div class="crumbs data_item">
      <el-breadcrumb separator-icon="ArrowRight" v-if="!task">
        <el-breadcrumb-item :to="{ path: '/workbench' }">
          <i class="iconfont icon-zhuye"></i
        ></el-breadcrumb-item>
        <el-breadcrumb-item>数据维护</el-breadcrumb-item>
        <el-breadcrumb-item>数据导入</el-breadcrumb-item>
      </el-breadcrumb>

      <el-breadcrumb separator-icon="ArrowRight" v-else>
        <el-breadcrumb-item :to="{ path: '/workbench' }">
          <i class="iconfont icon-zhuye"></i
        ></el-breadcrumb-item>
        <el-breadcrumb-item>数据维护</el-breadcrumb-item>
        <el-breadcrumb-item @click="task = false" style="cursor: pointer"
          >数据导入</el-breadcrumb-item
        >
        <el-breadcrumb-item>任务设置</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="data_item flex_between" v-if="!task">
      <div class="flex_start operation">
        <div class="flex_start">
          <span>任务日期：</span>
          <el-date-picker
            v-model="dateTime"
            type="date"
            placeholder="请选择"
            @change="changeType"
          />
        </div>
      </div>

      <div class="flex_start">
        <el-button class="data_btns flex_center" @click="importFn">
          <i class="icon iconfont icon-daochu"></i>
          <span>批量导入</span>
        </el-button>

        <el-button class="data_btns flex_center">
          <i class="icon iconfont icon-daochu"></i>
          <span>数据状态</span>
        </el-button>

        <el-button class="data_btns flex_center" @click="setting">
          <i class="icon iconfont icon-daochu"></i>
          <span>任务设置</span>
        </el-button>
      </div>
    </div>

    <template v-if="!task">
      <div class="data_box">
        <div class="data_title">电网运行实际信息</div>

        <el-table
          :data="forecastList.data"
          :header-cell-style="{
            background: '#022C63',
            height: '60px',
            color: '#FFFFFF',
          }"
          :cell-style="{
            background: '#0B183C',
            padding: '15px 0',
            color: '#FFFFFF',
          }"
          class="forecast_table"
        >
          <el-table-column prop="label" label="数据类型" align="center" />
          <el-table-column prop="date" label="数据日期" align="center" />
          <el-table-column prop="num" label="数据数量" align="center" />
          <el-table-column prop="dataType" label="数据状态" align="center">
            <template #default="scope">
              <!-- <div class="flex_center"><div class="flex_start tag_box"><span class="tag" :class="scope.row.dataType==1?'tag_blue':scope.row.dataType==2?'tag_green':''"></span> -->
              <div class="flex_center">
                <div class="tag_box flex_start">
                  <div
                    :class="
                      scope.row.dataType == 1
                        ? 'tag_blue'
                        : scope.row.dataType == 2
                        ? 'tag_green'
                        : 'tag'
                    "
                  ></div>
                  <div>
                    {{
                      scope.row.dataType == 0
                        ? "未导入"
                        : scope.row.dataType == 1
                        ? "未完整"
                        : scope.row.dataType == 2
                        ? "完整"
                        : ""
                    }}
                  </div>
                </div>
              </div>
              <!-- </div></div> -->
            </template>
          </el-table-column>
          <el-table-column prop="update" label="更新时间" align="center" />
          <el-table-column label="操作" align="center">
            <el-button type="primary" link>导入</el-button>
          </el-table-column>
        </el-table>
      </div>
      <div class="data_box">
        <div class="data_title">电网运行预测信息</div>

        <el-table
          :data="forecastList.data"
          :header-cell-style="{
            background: '#022C63',
            height: '60px',
            color: '#FFFFFF',
          }"
          :cell-style="{
            background: '#0B183C',
            padding: '15px 0',
            color: '#FFFFFF',
          }"
          class="forecast_table"
        >
          <el-table-column prop="label" label="数据类型" align="center" />
          <el-table-column prop="date" label="数据日期" align="center" />
          <el-table-column prop="num" label="数据数量" align="center" />
          <el-table-column prop="dataType" label="数据状态" align="center">
            <template #default="scope">
              <!-- <div class="flex_center"><div class="flex_start tag_box"><span class="tag" :class="scope.row.dataType==1?'tag_blue':scope.row.dataType==2?'tag_green':''"></span> -->
              <div class="flex_center">
                <div class="tag_box flex_start">
                  <div
                    :class="
                      scope.row.dataType == 1
                        ? 'tag_blue'
                        : scope.row.dataType == 2
                        ? 'tag_green'
                        : 'tag'
                    "
                  ></div>
                  <div>
                    {{
                      scope.row.dataType == 0
                        ? "未导入"
                        : scope.row.dataType == 1
                        ? "未完整"
                        : scope.row.dataType == 2
                        ? "完整"
                        : ""
                    }}
                  </div>
                </div>
              </div>
              <!-- </div></div> -->
            </template>
          </el-table-column>
          <el-table-column prop="update" label="更新时间" align="center" />
          <el-table-column label="操作" align="center">
            <el-button type="primary" link>导入</el-button>
          </el-table-column>
        </el-table>
      </div>
    </template>

    <template v-else>
      <div class="data_box">
        <div class="data_title">每日导入任务选择</div>

        <div class="task_selection">
          <div
            class="selection_column border_bottom flex_start column_title_top"
          >
            <div class="column_container flex_start border_right">
              <div class="border_right">
                <el-checkbox class="column_check" v-model="checked1" />
              </div>
              <div class="column_label">数据类型</div>
            </div>
            <div class="column_label">
              需导入日期（假设明天为D日，下月为M日）
            </div>
          </div>

          <div class="selection_title column_title_bottom">
            电网预测运行信息
          </div>

          <div class="selection_container flex_start">
            <div class="selection_key border_right flex_start">
              <div class="key_label">
                <div
                  class="key_item flex_start"
                  v-for="(item, index) in forecastCheck.data"
                  :key="index"
                >
                  <el-checkbox class="column_check" v-model="item.flag" />
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </div>

            <div class="value_label">
              <div
                class="value_item flex_start"
                v-for="(item, index) in forecastCheck.data"
                :key="index"
              >
                <span class="value_point">D - </span>
                <el-input v-model="item.day" />
                <span>日</span>
              </div>
            </div>
          </div>

          <div class="selection_title">电网运行实际信息</div>

          <div class="selection_container flex_start">
            <div class="selection_key border_right flex_start">
              <div class="key_label">
                <div
                  class="key_item flex_start"
                  v-for="(item, index) in actual.data"
                  :key="index"
                >
                  <el-checkbox class="column_check" v-model="item.flag" />
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </div>

            <div class="value_label">
              <div
                class="value_item flex_start"
                v-for="(item, index) in actual.data"
                :key="index"
              >
                <span class="value_point">D - </span>
                <el-input v-model="item.day" />
                <span>日</span>
              </div>
            </div>
          </div>

          <div class="selection_title">交易电量</div>

          <div class="selection_container flex_start">
            <div class="selection_key border_right flex_start">
              <div class="key_label">
                <div
                  class="key_item flex_start"
                  v-for="(item, index) in transaction.data"
                  :key="index"
                >
                  <el-checkbox class="column_check" v-model="item.flag" />
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </div>

            <div class="value_label">
              <div
                class="value_item flex_start"
                v-for="(item, index) in transaction.data"
                :key="index"
              >
                <span class="value_point">D - </span>
                <el-input v-model="item.day" />
                <span>日</span>
              </div>
            </div>
          </div>

          <div class="selection_title">现货价格</div>

          <div class="selection_container flex_start">
            <div class="selection_key border_right flex_start">
              <div class="key_label">
                <div
                  class="key_item flex_start"
                  v-for="(item, index) in stockList.data"
                  :key="index"
                >
                  <el-checkbox class="column_check" v-model="item.flag" />
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </div>

            <div class="value_label">
              <div
                class="value_item flex_start"
                v-for="(item, index) in stockList.data"
                :key="index"
              >
                <span class="value_point">D - </span>
                <el-input v-model="item.day" />
                <span>日</span>
              </div>
            </div>
          </div>

          <div class="selection_title">天气数据</div>

          <div class="selection_container flex_start">
            <div class="selection_key border_right flex_start">
              <div class="key_label">
                <div
                  class="key_item flex_start"
                  v-for="(item, index) in weatherList.data"
                  :key="index"
                >
                  <el-checkbox class="column_check" v-model="item.flag" />
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </div>

            <div class="value_label">
              <div
                class="value_item flex_start"
                v-for="(item, index) in weatherList.data"
                :key="index"
              >
                <span class="value_point">D - </span>
                <el-input v-model="item.day" />
                <span>日</span>
              </div>
            </div>
          </div>

          <div class="selection_title">客户分时电量</div>

          <div class="selection_container flex_start">
            <div class="selection_key border_right flex_start">
              <div class="key_label">
                <div
                  class="key_item flex_start"
                  v-for="(item, index) in timeList.data"
                  :key="index"
                >
                  <el-checkbox class="column_check" v-model="item.flag" />
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </div>

            <div class="value_label">
              <div
                class="value_item flex_start"
                v-for="(item, index) in timeList.data"
                :key="index"
              >
                <span class="value_point">D - </span>
                <el-input v-model="item.day" />
                <span>日</span>
              </div>
            </div>
          </div>

          <div class="selection_title">客户月度电量</div>

          <div class="selection_container flex_start">
            <div class="selection_key border_right flex_start">
              <div class="key_label">
                <div
                  class="key_item flex_start"
                  v-for="(item, index) in monthly.data"
                  :key="index"
                >
                  <el-checkbox class="column_check" v-model="item.flag" />
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </div>

            <div class="value_label">
              <div
                class="value_item flex_start"
                v-for="(item, index) in monthly.data"
                :key="index"
              >
                <span class="value_point">每月</span>
                <el-input v-model="item.dayStart" />
                <span>日 ~ </span>
                <el-input v-model="item.dayEnd" />
                <span>日 考核&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span>M - </span>
                <el-input v-model="item.month" />
                <span>月 数据</span>
              </div>
            </div>
          </div>

          <div class="selection_title">批发结算数据</div>

          <div class="selection_container flex_start">
            <div class="selection_key border_right flex_start">
              <div class="key_label">
                <div
                  class="key_item flex_start"
                  v-for="(item, index) in wholesale.data"
                  :key="index"
                >
                  <el-checkbox class="column_check" v-model="item.flag" />
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </div>

            <div class="value_label">
              <div
                class="value_item flex_start"
                v-for="(item, index) in wholesale.data"
                :key="index"
              >
                <template v-if="index == wholesale.data.length - 1">
                  <span class="value_point">每月</span>
                  <el-input v-model="item.dayStart" />
                  <span>日 ~ </span>
                  <el-input v-model="item.dayEnd" />
                  <span>日 考核&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <span>M - </span>
                  <el-input v-model="item.month" />
                  <span>月 数据</span>
                </template>

                <template v-else>
                  <span class="value_point">D - </span>
                  <el-input v-model="item.month" />
                  <span>日</span>
                </template>
              </div>
            </div>
          </div>

          <div class="selection_title">零售结算数据</div>

          <div class="selection_container flex_start border_bottom">
            <div class="selection_key border_right flex_start">
              <div class="key_label">
                <div
                  class="key_item flex_start"
                  v-for="(item, index) in retail.data"
                  :key="index"
                >
                  <el-checkbox class="column_check" v-model="item.flag" />
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </div>

            <div class="value_label">
              <div
                class="value_item flex_start"
                v-for="(item, index) in retail.data"
                :key="index"
              >
                <span class="value_point">每月</span>
                <el-input v-model="item.dayStart" />
                <span>日 ~ </span>
                <el-input v-model="item.dayEnd" />
                <span>日 考核&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span>M - </span>
                <el-input v-model="item.month" />
                <span>月 数据</span>
              </div>
            </div>
          </div>

          <div class="selection_btns flex_center">
            <el-button type="primary" size="large " @click="task = false"
              >提交</el-button
            >
            <el-button type="info" size="large " plain @click="task = false"
              >关闭</el-button
            >
          </div>
        </div>
      </div>
    </template>

    <importTemplate
      :dialogVisible="dialogImport"
      title="导入负荷预测信息"
      isSize="40%"
      @closeImport="dialogImport = false"
    ></importTemplate>
  </div>
</template>

<script>
import dialog from "../../components/dialog/dialog.vue";
import importTemplate from "../../components/importTemplate/importTemplate.vue";
import { reactive, ref, getCurrentInstance, nextTick, onMounted } from "vue";
export default {
  name: "dataImport",
  components: {
    importTemplate,
    dialog,
  },
  setup() {
    const { proxy } = getCurrentInstance(); // proxy是组件实例化对象

    let dateTime = ref(new Date());
    let dialogImport = ref(false);

    const importFn = () => {
      dialogImport.value = true;
      console.log(dialogImport.value);
    };

    const changeType = (val) => {};
    // dataType 0 未导入  1 未完整  2 完整
    let forecastList = reactive({
      data: [
        {
          id: 1,
          label: "负荷预测信息",
          date: "2023-07-26",
          num: 1,
          dataType: 2,
          update: "2023-07-26",
        },
        {
          id: 1,
          label: "备用预测信息",
          date: "2023-07-26",
          num: 1,
          dataType: 0,
          update: "2023-07-26",
        },
        {
          id: 1,
          label: "必开必停机组预测信息",
          date: "2023-07-26",
          num: 1,
          dataType: 2,
          update: "2023-07-26",
        },
        {
          id: 1,
          label: "机组检修预测信息",
          date: "2023-07-26",
          num: 1,
          dataType: 2,
          update: "2023-07-26",
        },
        {
          id: 1,
          label: "阻塞预测信息",
          date: "2023-07-26",
          num: 1,
          dataType: 1,
          update: "2023-07-26",
        },
      ],
    });

    let task = ref(false);

    let forecastCheck = reactive({
      data: [
        { flag: false, name: "负荷预测信息", day: 0 },
        { flag: false, name: "备用预测信息", day: 0 },
        { flag: false, name: "必开必停组预测信息", day: 0 },
        { flag: false, name: "机组检修预测信息", day: 0 },
        { flag: false, name: "阻塞预测信息", day: 0 },
      ],
    });

    let actual = reactive({
      data: [
        { flag: false, name: "负荷预测信息", day: 0 },
        { flag: false, name: "备用预测信息", day: 0 },
        { flag: false, name: "必开必停组预测信息", day: 0 },
        { flag: false, name: "机组检修预测信息", day: 0 },
        { flag: false, name: "阻塞预测信息", day: 0 },
      ],
    });

    let transaction = reactive({
      data: [
        { flag: false, name: "日前电量", day: 0 },
        { flag: false, name: "实时电量", day: 0 },
      ],
    });

    let stockList = reactive({
      data: [
        { flag: false, name: "日前价格统一结算点", day: 0 },
        { flag: false, name: "实时价格统一结算点", day: 0 },
        { flag: false, name: "日前节点电价", day: 0 },
        { flag: false, name: "实时节点电价", day: 0 },
        { flag: false, name: "日前全省节点平均电价", day: 0 },
        { flag: false, name: "实时全省节点平均电价", day: 0 },
      ],
    });

    let weatherList = reactive({
      data: [
        { flag: false, name: "全省平均气温", day: 0 },
        { flag: false, name: "西南降水", day: 0 },
        { flag: false, name: "各地市温度", day: 0 },
      ],
    });

    let timeList = reactive({
      data: [
        { flag: false, name: "日出清电量", day: 0 },
        { flag: false, name: "历史电量", day: 0 },
        { flag: false, name: "粤易电", day: 0 },
      ],
    });

    let monthly = reactive({
      data: [
        { flag: false, name: "月度申报电量", dayStart: 0, dayEnd: 0, month: 0 },
        { flag: false, name: "月度实际电量", dayStart: 0, dayEnd: 0, month: 0 },
      ],
    });
    let wholesale = reactive({
      data: [
        { flag: false, name: "日清算结果", dayStart: 0, dayEnd: 0, month: 0 },
        { flag: false, name: "日账单", dayStart: 0, dayEnd: 0, month: 0 },
        { flag: false, name: "月账单", dayStart: 0, dayEnd: 0, month: 0 },
      ],
    });

    let retail = reactive({
      data: [
        {
          flag: false,
          name: "交易中心零售账单",
          dayStart: 0,
          dayEnd: 0,
          month: 0,
        },
      ],
    });

    const setting = () => {
      task.value = true;
    };

    onMounted(() => {
      nextTick(() => {});
    });
    return {
      dateTime,
      changeType,
      forecastList,
      task,
      setting,
      forecastCheck,
      actual,
      transaction,
      stockList,
      weatherList,
      timeList,
      monthly,
      wholesale,
      retail,
      dialogImport,
      importFn,
    };
  },
};
</script>

<style lang="scss" scoped>
.data_box {
  // display: flex;
  // justify-content: space-between;
  margin-bottom: 15px;
}

.data_title {
  margin: 16px 0;
  font-size: 16px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-indent: 1em;

  &::before {
    display: block;
    content: "";
    width: 6px;
    height: 20px;
    background: linear-gradient(135deg, #0081ff 0%, #22cce2 100%);
    border-radius: 3px 3px 3px 3px;
  }
}

.data_container {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  .data_item {
    width: 100%;
    margin-bottom: 24px;

    .operation {
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;

      &:deep(.el-input__wrapper) {
        background: #1f2935;
        box-shadow: none;
      }
      &:deep(.el-input__inner) {
        color: #ffffff;
      }
    }
  }
  .crumbs {
    font-size: 14px;
    &:deep(.el-breadcrumb__item:last-child .el-breadcrumb__inner) {
      color: #ffffff;
    }
  }
}

.forecast_table {
  width: 100%;
  background: #0b183c;

  border-radius: 8px;
  --el-table-border: 2px solid #090a1f;
  --el-table-tr-bg-color: transparent;

  &:deep(.el-scrollbar) {
    padding: 0 10px 15px;
    box-shadow: inset 0px 0px 10px 0px #3767ad;
  }
  &:deep(.el-table__inner-wrapper::before) {
    background: #090a1f;
  }
}
.tag_box {
  min-width: 70px;
}
.tag {
  width: 10px;
  height: 10px;
  background: #ff8a48;
  margin-right: 8px;
  border-radius: 50%;
  display: inline-block;
}
.tag_green {
  width: 10px;
  height: 10px;
  background: #09b66d;
  margin-right: 8px;
  border-radius: 50%;
  display: inline-block;
}
.tag_blue {
  width: 10px;
  height: 10px;
  background: #22cce2;
  margin-right: 8px;
  border-radius: 50%;
  display: inline-block;
}
.data_btns {
  background: linear-gradient(180deg, #3090e4 0%, #09d3d5 100%);
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  margin-right: 15px;
  border: none;
  &:deep(.icon) {
    padding-right: 10px;
  }
}

.task_selection {
  padding: 6px 6px;
  box-shadow: inset 0px 0px 10px 0px #3767ad;
  border-radius: 8px;
  .selection_container {
    background: #0b183c;
    .key_label,
    .value_label {
      width: 100%;
      display: flex;
      align-content: flex-start;
      // flex-direction: column;
      flex-wrap: wrap;
      padding: 18px 0;
      .key_item,
      .value_item {
        width: 100%;
        height: 45px;
      }

      &:deep(.el-input) {
        width: 140px;
        height: 35px;
        margin: 0 8px;
      }
      &:deep(.el-input__wrapper) {
        background: #0b183c;
        .el-input__inner {
          text-align: center;
        }
      }
    }
    .value_label {
      box-sizing: border-box;
      margin-left: 16px;
    }
  }
  .selection_title {
    background: #022c63;
    padding: 18px 30px;
    font-size: 16px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #ffffff;
    border-radius: 8px;
  }
  .selection_column {
    background: #022c63;
    .column_label {
      padding: 18px 16px;
    }
    .column_container {
      width: 620px;
    }
  }
  .column_check {
    // height: 100%;
    margin: 13px 22px;
  }
  .selection_key {
    width: 1003px;
  }
  // .selection_value{
  // width:1000px;
  // width: calc(100% - 650px);
  // }
  .border_bottom {
    border-bottom: 1px solid #5b6d99;
  }
  .border_right {
    border-right: 1px solid #5b6d99;
  }
}

.value_point {
  width: 33px;
}

.selection_btns {
  // width: 100%;
  padding: 32px;
  background: #0b183c;
}
.column_title_top {
  border-radius: 8px 8px 0 0 !important;
}
.column_title_bottom {
  border-radius: 0 0 8px 8px !important;
}
</style>
