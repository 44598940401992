<template>
  <div class="import_container">
   <el-dialog
     v-model="dialogVisible"
     align-center
     :width="isSize?isSize:'50%'"
     :title="title"
     @close="$emit('closeImport')"
       
        >
        <div class="import_content">
           <div class="flex_start import_text">1.点击下载<div class="template">导入模板</div>，将要导入的数据填充到导入模板文件中。</div>
           <div class="flex_start import_text">2.请选择需要导入的文件</div>

            <el-upload
                ref="uploadRef"
                class="upload-demo"
                action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
                :auto-upload="false"
            >
                <template #trigger>
                <el-button type="primary" class="select_btn">选择文件</el-button>
                </template>
            </el-upload>

           <div class="attention">
            <div class="attention_title">注意事项</div>
            <div class="attention_label flex_start">模板不可修改，如删除表头、删除某一列等操作；</div>
            <div class="attention_label flex_start">单次导入的数据，不可超过3000条。</div>

           </div>

             <div class="flex_end">
                   <el-button type="info" plain size="large" @click="$emit('closeImport')">取消</el-button>
                   <el-button type="primary" size="large" @click="$emit('closeImport')">导入</el-button>
             </div>
        </div>
   </el-dialog>

  </div>
</template>

<script>
export default {
  props: {
      dialogVisible:Boolean,
        title:String,
        isSize:String
    },
}
</script>

<style  lang="scss" scoped>
.import_container{
    &:deep(.el-dialog){
        --el-dialog-bg-color:#0B183C;
        box-shadow: inset 0px 0px 10px 0px #3767AD;
        border-radius: 8px;
    }
    &:deep(.el-dialog__header){
        padding-left: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 2px solid #293657;
        margin-right: 0;

              &::before {
                display:block;
            content: '';
            width: 4px;
            height: 25px;
            background: linear-gradient(135deg, #0081FF 0%, #22CCE2 100%);
            border-radius: 0px 2px 2px 0px;
         }
         .el-dialog__title{
        text-indent:1em;
        font-size: 16px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
         }
    }

    .import_content{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        font-size: 14px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        .import_text{
            margin-bottom: 12px;
        }
        .template{
            color: #0256FF;
        }

        .attention{
            border: 1px solid #293657;
            border-radius: 8px;
            padding: 20px 24px;
            margin-bottom: 32px;
            .attention_title{
                font-size: 14px;
                font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                font-weight: bold;
                color: #FFFFFF;
                margin-bottom: 20px;
            }
            .attention_label{
                font-size: 14px;
                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                font-weight: 500;
                color: #FFFFFF;
                margin-bottom: 15px;
                text-indent: 1em;

            &::before {
            display:block;
            content: '';
            width: 6px;
            height: 6px;
            background:  #FF8A48;
            border-radius:50%;
         }

            }
        }
    }
    .select_btn{
        margin: 16px 0;
        height: 40px;
        background: linear-gradient(180deg, #3090E4 0%, #09D3D5 100%);
    }
}

</style>