<template>

        <el-dialog
           v-model="dialogVisible"
            align-center
            :width="isSize?isSize:'50%'"
            :title="title"
       
        >
        6666
            <!-- <div :style="noPadding?'':'padding:20px'">
                <div class="flex" style="border-bottom:1px solid #dedede;padding-bottom:10px">
                    <div style="border-left:5px solid #409eff;padding-left:5px">{{title}}</div>
                    <div>
                        <el-button type="primary" @click="$emit('closeDrawer')">返回</el-button>
                    </div>
                </div>
                <div style="height:calc(100vh - 100px);overflow:auto;padding:10px 0">
                    <slot></slot>
                </div>
            </div> -->
        </el-dialog>

</template>

<script>
export default {
    name: '',
    props: {
      dialogVisible:Boolean,
        title:String,
        noPadding:Boolean,
        isSize:String
    },
    components: {

    },
    data() {
        return {

        };
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    mounted() {

    },
    methods: {

    },
};
</script>

<style scoped lang="scss">

</style>
